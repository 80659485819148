import React from 'react';
import PropTypes from 'prop-types';
import {
    TransitionGroup,
    Transition as ReactTransition,
} from 'react-transition-group';

const timeout = 120;
const getTransitionStyles = {
    entering: {
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in`,
        opacity: 1,
    },
    exiting: {
        // transition: `opacity ${timeout / 2}ms ease-out`,
        opacity: 0,
        display: 'none',
    },
};

export default function Transition({ children, location }) {
    return (
        <TransitionGroup role="main" className="main" component="section">
            <ReactTransition
                key={location.pathname}
                timeout={{
                    enter: timeout,
                    exit: timeout,
                }}
            >
                {(status) => (
                    <div
                        style={{
                            ...getTransitionStyles[status],
                        }}
                    >
                        {children}
                    </div>
                )}
            </ReactTransition>
        </TransitionGroup>
    );
}

Transition.propTypes = {
    location: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
