import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';

// import { history } from './configureStore';

import settings from './ducks/settings';
import loadout from './ducks/loadout';

export default combineReducers({
    // router: connectRouter(history),
    settings,
    loadout,
});
