const description =
    'Tips and tricks for achieving Damascus in Call of Duty: Modern Warfare.';
const author = 'OKCOKER LLC';
const gaTrackingId = 'UA-174457401-1';
const siteUrl =
    process.env.NODE_ENV === 'production'
        ? 'https://damascusgrind.com'
        : 'http://localhost:8000';
const siteIcon = siteUrl + '/favicon.png';

module.exports.siteMetadata = {
    title: 'Damascus Grind',
    author: author,
    twitterHandle: 'MrWigglesRL',
    siteUrl: siteUrl,
    siteIcon: siteIcon,
    description: description,
    gaTrackingId: gaTrackingId,
    keywords: 'Call of Duty, Warzone, Damascus, Multiplayers',
};
