import React from 'react';
import Layout from '~templates/Layout';

// eslint-disable-next-line
export default function wrapPageElement({ element, props }) {
    // eslint-disable-next-line
    if (props.location.pathname.match(/^\/404/)) {
        return element;
    }
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return <Layout {...props}>{element}</Layout>;
}
