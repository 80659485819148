import { stringify } from 'query-string';
import { siteMetadata } from '../../config/site';

// require('isomorphic-fetch');

// Since fetch doesn't fail based on status codes >= 300
// we need to have this wrapper that will cause consuming code to
// act appropriately when there is a bad status code.
export function request(url, data = {}, getRawResponse = false) {
    if (!url) {
        return Promise.reject(`Invalid url: ${url}`);
    }

    const { body, headers = {}, ...rest } = data;
    const defaultContentTypeHeader =
        'application/x-www-form-urlencoded; charset=utf-8';

    const allHeaders = Object.keys(headers).map((h) => h.toLowerCase());

    if (allHeaders.indexOf('content-type') === -1) {
        headers['content-type'] = defaultContentTypeHeader;
    }

    const options = {
        method: 'GET',
        // Comment this out so we dont send an extra preflight request
        headers,
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        credentials: 'include',
        ...rest,
    };
    const lowercase = options.method.toLowerCase();

    if (body && Object.keys(body).length) {
        if (lowercase !== 'get' && lowercase !== 'head') {
            options.body = JSON.stringify(body);

            if (options.headers['content-type'] === defaultContentTypeHeader) {
                options.body = stringify(body);
            }
        } else {
            console.info('Ignoring body', body, 'because of request method');
        }
    }

    // Allow for relative urls to be fetched on server
    if (typeof window === 'undefined' && url.match(/^\/\w+/)) {
        url = `${siteMetadata.siteUrl}${url}`;
    }

    const promise = fetch(url, options);

    if (getRawResponse) {
        return promise;
    }

    return promise
        .then((response) => {
            return Promise.all([
                response.status,
                // Not using response.json() here because some requests
                // like OPTIONS requests return a non JSON response. Maybe
                // if we wanted to make things cleaner here, we could check
                // the request headers for application/json and return
                // response.json() specifically for that case, otherwise .text()
                response.text(),
            ]);
        })
        .then(([status, text]) => {
            let ret = text;

            try {
                ret = JSON.parse(text);
            } catch (e) {} // eslint-disable-line no-empty

            if (status >= 200 && status < 300) {
                return ret;
            }

            throw ret;
        });
}
// t: current time
// b: beginning value
// c: change in value (destination value - beginning value)
// d: duration
// s: overshoot ammount (optional - used only on *back easing)

/* eslint-disable */
export function easeOutBack(t, b, c, d, s) {
    if (s == undefined) s = 1.70158;
    return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
}

export function easeOutExpo(t, b, c, d) {
    return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
}
/* eslint-enable */

export function alterExternalLinks() {
    Array.from(document.querySelectorAll('a')).forEach((link) => {
        if (link.hostname !== window.location.hostname) {
            link.target = '_blank';
            link.rel = 'nofollow noopener';
        }
    });
}

export function fixTabFocus() {
    document.body.addEventListener('keydown', (e) => {
        // Tab
        if (e.which === 9) {
            document.body.classList.add('showfocus');
        }
    });

    document.body.addEventListener('click', () => {
        document.body.classList.remove('showfocus');
    });
}

// Inclusive
export function randomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomItem(arr) {
    return arr[randomNumberBetween(0, arr.length - 1)];
}

export function loadScript(src, id, appendTo) {
    if (!src || !id) {
        throw new Error('Must provide a src to load and an id');
    }

    if (!appendTo) {
        appendTo = document.body;
    }

    return new Promise((resolve, reject) => {
        let script = document.getElementById(id);
        let counter = 40;

        // Set a timer because if we're here, that means another script has
        // already loaded this script src but it hasnt loaded yet. In that
        // case we need to wait for data-loaded to be set before calling
        // the scripLoaded callback
        const checkLoaded = () => {
            // eslint-disable-line
            counter -= 1;

            // Just reject if it's been about 40 seconds with no load
            if (counter === 0) {
                reject(`Could not load ${src} after 40 seconds`);
                return;
            }

            if (script.getAttribute('data-loaded')) {
                resolve({
                    firstLoad: false,
                });
                return;
            }

            setTimeout(checkLoaded, 1000);
        };

        if (script) {
            checkLoaded();
            return;
        }

        script = document.createElement('script');

        script.id = id;
        script.onload = () => {
            script.setAttribute('data-loaded', true);
            resolve({
                firstLoad: true,
            });
        };
        script.onerror = reject;
        script.src = src;

        appendTo.appendChild(script);
    });
}

export function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [
              parseInt(result[1], 16),
              parseInt(result[2], 16),
              parseInt(result[3], 16),
          ]
        : null;
}

export function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // eslint-disable-line
}

export function interpolate(start, end, steps, count) {
    const s = start;
    const e = end;
    const final = s + ((e - s) / steps) * count;
    return Math.floor(final);
}

export class Color {
    constructor(r, g, b) {
        this.r = r;
        this.g = g;
        this.b = b;
    }

    getColors() {
        return {
            r: this.r,
            g: this.g,
            b: this.b,
        };
    }
}

export function getColorRange(startColor, endColor, howMany) {
    const color1 = new Color(...hexToRgb(startColor));
    const color2 = new Color(...hexToRgb(endColor));
    const count = howMany - 1;
    const range = [];

    for (let i = 0, len = howMany; i < len; i++) {
        const r = interpolate(color1.r, color2.r, count, i);
        const g = interpolate(color1.g, color2.g, count, i);
        const b = interpolate(color1.b, color2.b, count, i);

        range.push(rgbToHex(r, g, b));
    }

    return range;
}

// Having scripts in the markdown files dont work.
export function reAppendScripts(containerId) {
    const container = document.getElementById(containerId);
    const scripts = Array.from(container.querySelectorAll('script'));

    let promise = Promise.resolve();
    const newScriptIds = [];

    scripts.forEach((script, i) => {
        // let previousNonScript = script.previousElementSibling;

        // while (previousNonScript && previousNonScript.tagName.toLowerCase() === 'script') {
        //     previousNonScript = previousNonScript.previousElementSibling;
        // }

        const src = script.src;
        const hasSrc = !!script.src;
        const html = script.innerHTML;
        const isRelativePath = hasSrc && src.indexOf('/') === 0;
        const isRemotePath = hasSrc && src.indexOf('http') === 0;
        let id;

        if (hasSrc && !(isRelativePath || isRemotePath)) {
            return;
        }

        script.parentNode.removeChild(script);

        if (src) {
            id = src.replace(/\W+/g, '');
            if (id) {
                newScriptIds.push(id);
                promise = promise.then(() => {
                    return loadScript(src, id);
                });
            }
            return;
        }

        const newScript = document.createElement('script');
        newScript.innerHTML = html;
        const scriptId = `markdownscript${i}`;
        newScript.id = scriptId;

        newScriptIds.push(scriptId);

        document.body.appendChild(newScript);

        // previousNonScript.insertAdjacentHTML('afterend', newScript.outerHTML);
    });

    return promise
        .catch((err) => {
            console.error(err);
        })
        .then(() => {
            return newScriptIds;
        });
}

let passiveSupported = null;

export function passiveOption() {
    if (passiveSupported !== null) {
        return passiveSupported ? { passive: true } : false;
    }

    try {
        const options = Object.defineProperty({}, 'passive', {
            get() {
                passiveSupported = true;
                return undefined;
            },
        });

        window.addEventListener('test', options, options);
        window.removeEventListener('test', options, options);
    } catch (err) {
        passiveSupported = false;
    }

    if (passiveSupported) {
        return { passive: true };
    }

    return false;
}
