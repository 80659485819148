import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { fixTabFocus } from '~utils/index';

import GlobalMeta from '~templates/meta/GlobalMeta';
import Transition from '~components/Transition';

export default function Layout({ location, children }) {
    // Tab focus hack
    useEffect(() => {
        fixTabFocus();
    }, []);

    ////////////////////
    // Event handlers //
    ////////////////////

    //////////////////////
    // Internal methods //
    //////////////////////
    return (
        <Fragment>
            <GlobalMeta location={location} />
            <Transition location={location}>{children}</Transition>
        </Fragment>
    );
}

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    location: PropTypes.object,
    isNotFound: PropTypes.bool,
};
