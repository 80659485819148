// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loadout-js": () => import("./../../src/pages/loadout.js" /* webpackChunkName: "component---src-pages-loadout-js" */),
  "component---src-templates-weapon-category-js": () => import("./../../src/templates/WeaponCategory.js" /* webpackChunkName: "component---src-templates-weapon-category-js" */),
  "component---src-templates-weapon-js": () => import("./../../src/templates/Weapon.js" /* webpackChunkName: "component---src-templates-weapon-js" */)
}

