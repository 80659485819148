export default {
    set(key, value) {
        try {
            if (typeof value !== 'string') {
                localStorage.setItem(key, JSON.stringify(value));
            } else {
                localStorage.setItem(key, value);
            }
            return true;
        } catch (e) {
            return null;
        }
    },

    get(key) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            try {
                return localStorage.getItem(key);
            } catch (ee) {
                return null;
            }
        }
    },

    remove(key) {
        try {
            localStorage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    },
};

export const STORAGE_KEY_SETTINGS = 'dg-settings';
export const STORAGE_KEY_WELCOME_MODAL = 'dg-welcome';
export const STORAGE_KEY_MY_LOADOUT = 'dg-my-loadout';
