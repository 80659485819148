import { PREFIX } from '~redux/ducks/settings';
import { ADD_WEAPON, UPDATE_WEAPON } from '~redux/ducks/loadout';
import storage, {
    STORAGE_KEY_SETTINGS,
    STORAGE_KEY_MY_LOADOUT,
} from '~utils/storage';

export default function middleware(store) {
    return (next) => (action) => {
        const { type } = action;

        if (type.startsWith(PREFIX)) {
            const result = next(action);
            const settingsState = store.getState().settings;

            storage.set(STORAGE_KEY_SETTINGS, settingsState);
            return result;
        }

        if ([ADD_WEAPON, UPDATE_WEAPON].includes(type)) {
            const result = next(action);
            const loadout = store.getState().loadout.list;

            storage.set(STORAGE_KEY_MY_LOADOUT, loadout);
            return result;
        }

        return next(action);
    };
}
