import { siteMetadata } from '../../config/site';

export default {
    /**
     * Track certain events around the app and send it to wherever we
     * are tracking events
     *
     * @param  {String} event   Event category
     * @param  {Object} options data to send to the tracker functions
     * @param {Array} trackers Array of tracker keys that should be used when sending the data payload
     */

    track(event, options = {}, trackers = ['ga']) {
        if (typeof window === 'undefined') {
            return;
        }

        if (!event) {
            throw new Error('Must provide an event category');
        }

        let tracked = 0;

        if (typeof window.gtag === 'function' && trackers.includes('ga')) {
            tracked += 1;
            const { eventAction, eventLabel, eventValue, ...rest } = options;

            // Need to call firebase.analytics before gtag()
            // https://firebase.google.com/docs/analytics/get-started?platform=web#firebase-gtag
            if (typeof window.firebase !== 'undefined') {
                window.firebase.analytics();
            }

            window.gtag('event', options.event_action || eventAction, {
                ...rest,
                event_category: event,
                event_label: options.event_label || eventLabel,
                value: options.value || eventValue
            });
        }

        if (
            typeof window.mixpanel === 'object' &&
            trackers.includes('mixpanel')
        ) {
            tracked += 1;
            window.mixpanel.track(event, options);
        }

        if (tracked !== trackers.length) {
            console.error(
                `${trackers.length -
                    tracked} tracker(s) from ${trackers} were not successful. Check why.`
            );
        }
    },

    pageView(path) {
        if (typeof window === 'undefined') {
            return;
        }

        if (typeof window.gtag === 'function') {
            window.gtag('config', siteMetadata.gaTrackingId, {
                page_path: path
            });
        }

        if (window.newrelic) {
            window.newrelic.setCurrentRouteName(path);
        }
    },

    error(err, fatal = false) {
        if (!process.env.BROWSER) {
            return;
        }

        if (typeof window.gtag === 'function') {
            // Need to call firebase.analytics before gtag()
            // https://firebase.google.com/docs/analytics/get-started?platform=web#firebase-gtag
            if (typeof window.firebase !== 'undefined') {
                window.firebase.analytics();
            }

            window.gtag('event', 'exception', {
                description: err.message,
                fatal
            });
        }

        if (window.newrelic) {
            window.newrelic.noticeError(err);
        }
    }
};

export const eventCategory = {
    ad: 'Advertisements',
    auth: 'Authentication',
    user: 'User',
    socialShare: 'Social Share',
    comment: 'Comments',
};

export const eventAction = {
    password: 'Password',
    facebook: 'Facebook',
    twitter: 'Twitter',
    google: 'Google',
    instagram: 'Instagram'
};

export const eventLabel = {
};
