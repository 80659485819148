import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { siteMetadata } from '~config/site';

export default function GlobalMeta({ location }) {
    const description = siteMetadata.description;
    const canonical = `${siteMetadata.siteUrl}${location.pathname}`;
    const title = siteMetadata.title;

    let websiteAction;
    let canonicalTag;

    if (location.pathname === '/') {
        canonicalTag = <link rel="canonical" href={canonical} />;
        websiteAction = (
            <script type="application/ld+json">
                {JSON.stringify([
                    {
                        name: siteMetadata.title,
                        url: siteMetadata.siteUrl,
                        // sameAs: [
                        //     'https://twitter.com/MrWigglesRL'
                        // ],
                        '@type': 'WebSite',
                        publisher: {
                            '@type': 'Organization',
                            logo: {
                                '@type': 'ImageObject',
                                url: siteMetadata.siteIcon,
                            },
                        },
                        '@context': 'https://schema.org',
                    },
                ])}
            </script>
        );
    }

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0"
            />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={canonical} />
            <meta property="og:image" content={siteMetadata.siteIcon} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            {canonicalTag}
            <meta name="twitter:domain" content={siteMetadata.siteUrl} />
            <meta name="twitter:site" content="@audiomack" />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:url" content={`${siteMetadata.siteUrl}`} />
            <meta
                name="twitter:creator"
                content={`@${siteMetadata.twitterHandle}`}
            />
            <meta
                name="twitter:image:src"
                content={`${siteMetadata.siteUrl}/favicon.png`}
            />
            <meta name="keywords" content={siteMetadata.keywords} />
            <meta name="referrer" content="origin" />
            {websiteAction}
        </Helmet>
    );
}

GlobalMeta.propTypes = {
    location: PropTypes.object.isRequired,
};
