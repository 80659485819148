require('./src/scss/style.scss');

const wrapRootElement = require('./config/wrapRootElement').default;
const wrapPageElement = require('./config/wrapPageElement').default;

module.exports.wrapRootElement = wrapRootElement;
module.exports.wrapPageElement = wrapPageElement;

const transitionDelay = 300;

module.exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
        const savedPosition = getSavedScrollPosition(location);
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        );
    }
    return false;
};
