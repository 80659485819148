import React from 'react';
import { Provider } from 'react-redux';

import api from '~api/index';
import rootReducer from '~redux/reducer';
import storage, { STORAGE_KEY_SETTINGS } from '~utils/storage';
import { configureStore } from '~redux/configureStore';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    let initialState = {};

    if (typeof window !== 'undefined') {
        const settings = storage.get(STORAGE_KEY_SETTINGS);

        if (settings) {
            initialState.settings = settings;
        }
    }

    const store = configureStore(initialState, rootReducer, api);

    if (
        typeof window !== 'undefined' &&
        process.env.NODE_ENV === 'development'
    ) {
        window.store = store;
    }

    return <Provider store={store}>{element}</Provider>;
};
