export const PREFIX = 'dg/loadout/';

export const ADD_WEAPON = `${PREFIX}ADD_WEAPON`;
export const UPDATE_WEAPON = `${PREFIX}UPDATE_WEAPON`;
export const LOAD_LIST = `${PREFIX}LOAD_LIST`;

const defaultState = {
    list: [],
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case ADD_WEAPON:
            return {
                ...state,
                list: Array.from(state.list).concat(action.weapon),
            };

        case UPDATE_WEAPON:
            return {
                ...state,
                list: Array.from(state.list).concat(action.weapon),
            };

        case LOAD_LIST:
            return {
                ...state,
                list: action.list,
            };

        default:
            return state;
    }
}

export function loadList(list) {
    return {
        type: LOAD_LIST,
        list,
    };
}
export function addWeapon(weapon) {
    return {
        type: ADD_WEAPON,
        weapon,
    };
}

export function updateWeapon(id, data) {
    return {
        type: UPDATE_WEAPON,
        id,
        data,
    };
}
