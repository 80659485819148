// import { request } from '../utils/index';

let baseUrl = process.env.API_URL;
let consumerKey = process.env.API_CONSUMER_KEY;
let consumerSecret = process.env.API_CONSUMER_SECRET;
let customHeaders = {};

function getConfig() {
    return {
        baseUrl,
        consumerKey,
        consumerSecret,
        customHeaders
    };
}

function setConfig(options = {}) {
    baseUrl = options.baseUrl || baseUrl;
    consumerKey = options.consumerKey || consumerKey;
    consumerSecret = options.consumerSecret || consumerSecret;
    customHeaders = options.customHeaders || customHeaders;
}

const api = Object.freeze({
});

if (typeof window === 'object' && process.env.NODE_ENV === 'development') {
    window.api = api;
}

export { setConfig, getConfig };
export default api;
