// import analytics, { eventCategory } from '../../utils/analytics';

/**
 * Send analytics calls based on event action
 */
export default function middleware() {
    return (next) => (action) => {
        const { type } = action;
        // const { currentUser } = store.getState();

        switch (type) {
            // case SET_EXTERNAL_RSS_FEED_URL:
            //     sendMixpanelEvent(currentUser, eventCategory.rss, {
            //         URL: action.url
            //     });
            //     break;

            default:
                break;
        }

        return next(action);
    };
}
