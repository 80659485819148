import { createStore, applyMiddleware } from 'redux';
import { createMemoryHistory, createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import promiseMiddleware from './middleware/promiseMiddleware';
import analyticsMiddleware from './middleware/analyticsMiddleware';
import storageMiddleware from './middleware/storageMiddleware';

// const history = process.env.NODE_ENV === 'development' ? createHashHistory() : createBrowserHistory();
export const history =
    typeof window !== 'undefined'
        ? createBrowserHistory()
        : createMemoryHistory();

export function configureStore(initialState, rootReducer, api) {
    const middlewares = [
        thunkMiddleware.withExtraArgument(api),
        promiseMiddleware,
        // routerMiddleware(history),
        // Making this last in case previous middleware transforms
        // any of the dispatched actions. Might not matter though...
        analyticsMiddleware,
        storageMiddleware,
    ];

    function bindMiddleware(middleware) {
        if (process.env.NODE_ENV === 'development') {
            // Dont show in CLI
            if (
                typeof window !== 'undefined' &&
                localStorage.getItem('REDUX_LOGGER') !== 'false'
            ) {
                const logger = createLogger();
                middleware.push(logger);
            }

            return composeWithDevTools(applyMiddleware(...middleware));
        }

        return applyMiddleware(...middleware);
    }

    const store = createStore(
        rootReducer,
        initialState,
        bindMiddleware(middlewares)
    );

    return store;
}
