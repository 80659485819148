export const PREFIX = 'dg/settings/';

export const DISPLAYED_WELCOME = `${PREFIX}DISPLAYED_WELCOME`;
export const UPDATE_SETTING = `${PREFIX}UPDATE_SETTING`;

const defaultState = {
    showDasmascusGunsOnly: true,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_SETTING:
            return {
                ...state,
                [action.name]: action.value,
            };

        default:
            return state;
    }
}

export function displayedWelcome() {
    return {
        type: DISPLAYED_WELCOME,
    };
}

export function updateSetting(name, value) {
    return {
        type: UPDATE_SETTING,
        name,
        value,
    };
}
